.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 50vh; /* Set height relative to the viewport height */
}

.carousel-image {
  width: 15vw; /* Set width relative to the viewport width */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0.5;
  margin: 0 2vw; /* Set margin relative to the viewport width */
  box-shadow: 12px 12px 12px grey;
}

.carousel-image.center-image {
  width: 40vw; /* Set center image width relative to the viewport width */
  height: auto; /* Maintain aspect ratio */
  opacity: 1;
  transform: scale(1.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 5vw; /* Set gap relative to the viewport width */
}

.footer-links span {
  font-weight: bold;
}

.footer-links a {
  text-decoration: none;
  color: #000;
  margin: 0 2vw; /* Set margin relative to the viewport width */
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel {
    height: 40vh; /* Adjust height for smaller screens */
  }

  .carousel-image {
    width: 100%; /* Adjust image width for smaller screens */
    margin: 0; /* Remove margin for smaller screens */
    opacity: 1; /* Make the image fully opaque */
  }

  .carousel-image:not(.center-image) {
    display: none; /* Hide non-center images */
  }

  .footer-links {
    flex-direction: column;
    gap: 5vh; /* Adjust gap for smaller screens */
  }

  .footer-links a {
    margin: 2vh 0; /* Adjust margin for smaller screens */
  }
}

@media (max-width: 480px) {
  .carousel {
    height: 35vh; /* Further adjust height for very small screens */
  }

  .carousel-image {
    width: 100%; /* Adjust image width for very small screens */
  }

  .carousel-image.center-image {
    width: 100%; /* Adjust center image width for very small screens */
    transform: none; /* Remove scaling for smaller screens */
  }

  .carousel-image:not(.center-image) {
    display: none; /* Hide non-center images */
  }
}
