.contact1 {
  background-color: #fff;
}

.thq-icon-medium {
  width: 50px;
  height: 50px;
}

.contact-contact20 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-section-title {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-content {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-text2 {
  text-align: center;
}
.contact-row {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.contact-content1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-contact-info {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-text3 {
  align-self: stretch;
  text-align: center;
}
.contact-text4 {
  text-align: center;
}
.contact-email {
  text-align: center;
}
.contact-content3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-contact-info1 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-text5 {
  align-self: stretch;
  text-align: center;
}
.contact-text6 {
  text-align: center;
}
.contact-phone {
  text-align: center;
}
.contact-content5 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-contact-info2 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact-text7 {
  align-self: stretch;
  text-align: center;
}
.contact-text8 {
  text-align: center;
}
.contact-address {
  text-align: center;
}
@media (max-width: 767px) {
  .contact-row {
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .contact-row {
    align-items: stretch;
  }
}

h3 {
  color: #c90000;
  font-weight: bold;
  margin-bottom: 20px;
}
