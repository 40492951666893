.footer-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.footer-copyright {
  margin: 0;
}

.footer-divider {
  width: 100%;
  border: none;
  border-top: 1px solid #000;
  margin: 10px 0;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-links span {
  font-weight: bold;
}

.footer-links a {
  text-decoration: none;
  color: #000;
  margin: 0 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}
