.homepage {
  text-align: center;
  color: #fff; /* Ensures text is white */
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 60px;
  background: url("./images/YYC.png") no-repeat center center/cover;
  position: relative;
  z-index: 1;
}

.homepage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: -1;
}

.logo-image h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
}

.logo-image p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #fff;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.buttons button {
  padding: 0.75rem 1.5rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.get-started {
  background-color: #c90000;
  color: #fff;
  border-radius: 50px;
}

.get-started:hover {
  background-color: #191919;
  color: #c90000;
  filter: drop-shadow(0px 0px 12px #c90000);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-image h1 {
    font-size: 2rem;
  }

  .logo-image p {
    font-size: 1rem;
  }

  .buttons .get-started {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .logo-image h1 {
    font-size: 1.5rem;
  }

  .logo-image p {
    font-size: 0.9rem;
  }

  .buttons .get-started {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}
